





































































import {Vue, Component} from 'vue-property-decorator'
import Confirmacion from '@/components/Confirmacion.vue'
import TablaReportes from '@/components/TablaReportes.vue'
import DetalleRepDespachoAlmacigueras from '@/components/Reportes/DetalleRepDespachoAlmacigueras.vue'
import { reporteriaAxios } from '@/plugins/axios';
import ResponseCustom from '@/entidades/ResponseCustom';

import { mensajes } from '@/plugins/notify'
import moment from 'moment';
@Component({
    components:{
        Confirmacion,
        TablaReportes,
        DetalleRepDespachoAlmacigueras
    }
})

export default class extends Vue
{
    componentName='Confirmacion'
    modal=false;
    modal2=false;
    desde='';
    hasta='';
    desdeModal=false;
    hastaModal=false;
    headers:any[]= 
    [
        {
          text: 'Usuario',
          align: 'start',
          sortable: false,
          value: 'usuario'
        },
        {
          text: 'Semana',
          align: 'end',
          sortable: false,
          value: 'semana'
        },
        {
          text: 'Hectareas',
          align: 'end',
          sortable: false,
          value: 'hectareas'
        },
        {
          text: 'Costo',
          align: 'end',
          sortable: false,
          value: 'costo'
        },
        //{ text: 'Acciones', align: 'center', value: 'acciones', sortable: false },        
    ];
    cabeceraDetalles:any[]=[
        {
          text: 'Planta',
          align: 'center',
          sortable: false,
          value: 'plantaName'
        },
        {
          text: 'Hacienda',
          align: 'center',
          sortable: false,
          value: 'haciendaName'
        },
        {
          text: 'Lote',
          align: 'center',
          sortable: false,
          value: 'loteName'
        },
        {
          text: 'Cantidad',
          align: 'center',
          sortable: false,
          value: 'cantidad'
        },
    ];
    usuarios:any[]=new Array();
    usuario={}
    semana=-1;
    anio=-1;
    anios:Array<Number>=[];
    items=new Array();
    detalles=new Array();

    get semanas(){
        if(this.anio!=-1){
            //var semanaActual = moment(CurrentDate, "YYYY-MM-DD").week();
            var SemanasDelAnio = moment(this.anio, "YYYY").weeksInYear();
            var salida:number[]=new Array();
            if(this.anio==Number(moment().format("YYYY"))){
                var CurrentDate=moment().format("YYYY-MM-DD");
                SemanasDelAnio=moment(CurrentDate, "YYYY-MM-DD").week();
            }
            for (let index = 1; index <= SemanasDelAnio; index++) {
                salida.push(index)
            }
            return salida;
        }else{
            return []
        }
    }

    async cargarDatos(){
        mensajes.advertencia('Cargando datos, por favor espere...')
        await reporteriaAxios({
            url:`HectareaTrabajada/ObtenerSemamaAll`,
            method:'get',
        }).then((data)=>{
            var resp:ResponseCustom<any>=data.data;
            console.log("Respuesta; ", resp)
            if(resp.isSuccess){
                this.items=resp.isResult;
            }else{
                mensajes.error('Error: '+ resp.isMessage);
            }
        }).catch((err)=>{
            mensajes.errorDeConexion();
        })
    }
    async cargarDatosFiltrados(){
        if(this.anio!=-1 && this.semana!=-1){
            
            //@ts-ignore
            if( this.usuario.usuarioId){
                //@ts-ignore
                var userId=this.usuario.usuarioId
                var ladata={
                        anio:this.anio,
                        semana:this.semana,
                        usuarioId:userId
                    }
                    console.log('La data es: ', ladata)
                await reporteriaAxios({
                    url:`HectareaTrabajada/ObtenerSemanaMayordomo`,
                    method:'POST',
                    data:ladata
                }).then((data)=>{
                    var resp:ResponseCustom<any>=data.data;
                    console.log("Respuesta; ", resp)
                    if(resp.isSuccess){
                        this.items=resp.isResult;
                        mensajes.exito('Datos cargados correctamente')
                    }else{
                        mensajes.error('Error: '+ resp.isMessage);
                    }
                }).catch((err)=>{
                    mensajes.errorDeConexion();
                })
            }else{
                mensajes.error('Especifique el usuario')
            }
        }else{
            mensajes.error('Por favor especifique el año y la semana')
        }
    }
    async obtenerPDF(item:any){
         console.log('Llamdo en el padre con: ', item)
        await reporteriaAxios({
            url:`DespachoAlmaciguera/GenerarPdf/${item.id}`,
            method:'get',
            responseType: 'blob'
        }).then((data)=>{
            const url = window.URL
                .createObjectURL(new Blob([data.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'despachoalmaciguera'+this.generarNombre(item.id)+'.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch((err)=>{
            console.log("Error: ", err)
        })
    }
    generarNombre(id:number){
        var limite=9;
        var cantidadDigitosId=id.toString().length;
        var restantes=limite-cantidadDigitosId;
        var salida='';
        for (let index = 0; index < restantes; index++) {
            salida=salida+'0';
        }
        return salida+id.toString();
    }
    async verDetalles(item:any){
        console.log('Llamdo en el padre con: ', item)
        await reporteriaAxios({
            url:`DespachoAlmaciguera/ObtenerDetalle/${item.id}`,
            method:'get',
        }).then((data)=>{
            var resp:ResponseCustom<any>=data.data;
            console.log("Respuesta; ", resp)
            if(resp.isSuccess){
                this.detalles=resp.isResult;
                this.modal=true;
                this.componentName='DetalleRepDespachoAlmacigueras'
            }else{
                console.log('Error: ', resp.isMessage);
            }
        }).catch((err)=>{
            console.log("Error: ", err)
        })
    }
    async cargarUsuarios(){
        
        await reporteriaAxios({
            url:`UsuarioMayordomo/ObtenerMayordomo`,
            method:'get',
        }).then((data)=>{
            var resp:ResponseCustom<any>=data.data;
            console.log("Respuesta usuario mayordomo; ", resp)
            if(resp.isSuccess){
                this.usuarios=resp.isResult;
                this.componentName='DetalleRepDespachoAlmacigueras'
            }else{
                console.log('Error: ', resp.isMessage);
            }
        }).catch((err)=>{
            console.log("Error: ", err)
        })
    }
    async created() {
        await this.cargarDatos();
        await this.cargarUsuarios();
        
        //ts-ignore
        var Anio=Number(moment().format("YYYY"));
        var AnioInicial=2020;
        this.anios=[];
        for (let index = AnioInicial; index <= Anio; index++) {
            
            this.anios.push(index);            
        }
        console.log('Creado año', Anio);
    }
}

